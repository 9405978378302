import React, { Component, Fragment } from 'react'
import { MenuItem, TextField, Icon, InputAdornment, OutlinedInput, Grid, Paper, Box, Button, Collapse, CircularProgress, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell, TableSortLabel } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import moment from 'moment';
import DatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange'
import { fetchStatusList, fetchPromotionDetailOverview, fetchPromotionList, createPromotion, fetchPromotionRewards, fetchFundsOverview } from '~/redux/actions/promotion'
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import FundOverview from '~/components/FundOverview'
import Notification from '~/components/Notification'
import './styles.scss'
import currency from 'currency.js';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CurrencyInput from '~/components/CurrencyInput';

const promotionStatusList = [{
	label: 'Draft',
	value: '2'
}, {
	label: 'Open',
	value: '3'
}, {
	label: 'In Progress',
	value: '4'
}, {
	label: 'Closed',
	value: '5'
}, {
	label: 'Expired',
	value: '6'
}]

class PromotionListView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			fetchingList: true,
			fetchingDetail: false,
			expandedItem: null,
			showFilter: false,
			page: 0,
			rowsPerPage: 10,
			filterOpen: false,
			createPromotionProgress: false,
			PromotionID: '',
			PromotionName: '',
			ExpirationDate: null,
			FulfillmentDate: null,
			Funds: "",
			Status: '',
			PromotionRewards: [],
			FundsOverview: [],
			PromotionDetailOverview: null,
			statusList: null,
			SortColumn: "",
			SortOrder: ""
		}
	}
	componentDidMount() {
		const { accessToken, portbu, oeid } = this.props.user.info;
		this.updatePromotionList();
		this.props.dispatch(fetchFundsOverview()).then((response) => {
			this.setState({ FundsOverview: this.props.promotion.fundsOverview });
		});

		this.getStatusList();
	}

	toggleListItem = (item, index) => {
		let { expandedItem, PromotionRewards } = this.state
		if (expandedItem === index) {
			expandedItem = null
		} else {
			expandedItem = index
		}
		this.setState({
			fetchingDetail: true,
			PromotionRewards: [],
			expandedItem
		}, () => {
			this.props.dispatch(fetchPromotionRewards({ PromotionID: item.PromotionID })).then(() => {
				//console.log("redeem rewards step", this.props.payments.redeemRewards);
				this.setState({
					fetchingDetail: false,
					PromotionRewards: this.props.promotion.promotionRewards,
				}, () => {
					setTimeout(() => {
						this.setState({ fetchingDetail: false })
					}, 1000)
				})
			})

			this.props.dispatch(fetchPromotionDetailOverview({ PromotionID: item.PromotionID })).then(() => {
				//console.log("redeem rewards step", this.props.payments.redeemRewards);
				this.setState({
					PromotionDetailOverview: this.props.promotion.promotionDetailOverview,
				})
			})
		})
	}

	handlePromotionEdit = (PromotionID) => {
		const { FundsOverview } = this.state;
		const expiredTotal = FundsOverview && FundsOverview[0] ? FundsOverview[0].ExpiredAmount : 0;
		this.props.history.push(`/promotion/${PromotionID}`, { expiredTotal: expiredTotal });
	}

	handleSorting(SortColumn) {
		const { SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
		this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingList: true }, () => {
			this.updatePromotionList(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
		});
	}

	filterCliCkFun = () => {
		this.setState({
			filterOpen: !this.state.filterOpen
		})
	}

	clearFilter = () => {
		this.setState({
			PromotionID: '',
			PromotionName: '',
			ExpirationDate: null,
			FulfillmentDate: null,
			Funds: '',
			page: 0,
			rowsPerPage: 10,
			Status: null,
		}, () => this.updatePromotionList());
	}

	handlePageChange = (event, page) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page
		}, () => this.updatePromotionList(SortColumn, newSortOrder))
	}

	handleRowsPerPageChange = (event) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10)
		}, () => this.updatePromotionList(SortColumn, newSortOrder))
	}

	updatePromotionList = (SortColumn, SortOrder) => {
		const { PromotionID, PromotionName, ExpirationDate, FulfillmentDate, Funds, Status, page, rowsPerPage } = this.state

		this.setState({
			fetchingList: true
		}, () => {
			this.props.dispatch(fetchPromotionList({ PromotionID, PromotionName, ExpirationDate, FulfillmentDate, Funds: Funds, Status, PageNo: page + 1, PageSize: rowsPerPage, SortColumn: SortColumn || "", SortOrder: SortOrder || "" })).then(() => {
				this.setState({
					isLoading: false,
					fetchingList: false
				})
			})
		})
	}

	createNewPromotion = () => {
		const { FundsOverview } = this.state;
		this.setState({
			createPromotionProgress: true
		}, async () => {
			await this.props.dispatch(createPromotion())

			const expiredTotal = FundsOverview && FundsOverview[0] ? FundsOverview[0].ExpiredAmount : 0;
			if (!this.props.promotion.createError) {
				const PromotionID = this.props.promotion.details.PromotionID
				this.props.history.push(`/promotion/${PromotionID}`, { expiredTotal: expiredTotal })
			} else {
				this.setState({
					createPromotionProgress: false
				})
			}
		})
	}

	getStatusList = () => {
		this.props.dispatch(fetchStatusList()).then((response) => {
			//set state here on success
			if (!response)
				return false;
			this.setState({
				statusList: response
			})
		})
	}

	render() {
		const { statusList, PromotionDetailOverview, PromotionRewards, FundsOverview, isLoading, createPromotionProgress, filterOpen, fetchingList, page, rowsPerPage, expandedItem, fetchingDetail, showFilter, PromotionID, PromotionName, ExpirationDate, FulfillmentDate, Funds, Status, SortColumn, SortOrder } = this.state
		const { user, promotion, permissions } = this.props;
		let claims = permissions.minified;

		//Check for expired fund
		const expiredTotal = FundsOverview.length ? FundsOverview.find(x => x.RewardStatusID == 14).RewardAmount : 0;

		if (isLoading) {
			return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
		}
		return (
			<Fragment>
				<Grid container justify="center" id="promotion-list-view">
					<Grid item xs={11}>
						<Box my={5}>
							<Paper square display="flex">
								<FundOverview
									params={{
										title: 'Funds Overview'
									}}
									assignedFunds={{
										fundTitle: "Assigned Funds",
										total: FundsOverview && FundsOverview[0] ? FundsOverview[0].AssignedFunds : 0,
										data: FundsOverview
									}}
									expiredFunds={{
										fundTitle: "Expired Funds",
										total: FundsOverview && FundsOverview[0] ? FundsOverview[0].ExpiredAmount : 0,
									}}
								/>
							</Paper>
						</Box>
					</Grid>
					<Grid item xs={11}>
						<div className="list-header">
							<Grid item xs={2}>
								<span className="item-count">Total {promotion.totalCount}</span>
							</Grid>
							<Grid item xs={3} md={3} lg={2}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									{createPromotionProgress ? (
										<Box justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
									) : (
										<span>
											{claims.includes("promotion_create") ?
												<Button
													variant="contained"
													className="downloadBtn"
													color="primary"
													style={{ textTransform: "capitalize" }}
													onClick={this.createNewPromotion}
												>
													Create Promotion
												</Button> : null}
										</span>
									)}
									<img className="filter-button" onClick={this.filterCliCkFun} src={require('~/assets/images/filter-icon.png')} alt="filter-icon" />
								</Box>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={11}>
						<Collapse hidden={!filterOpen} in={filterOpen}>
							<Grid container justify="space-between" hidden className="filterWrap">
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Promo ID</label>
										<TextField
											fullWidth={true}
											autoComplete="off"
											variant="outlined"
											value={PromotionID}
											inputProps={{
												ref: el => this.promotionId = el
											}}
											onChange={event => this.setState({ PromotionID: event.target.value })}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Promotion Name</label>
										<TextField
											fullWidth={true}
											autoComplete="off"
											variant="outlined"
											value={PromotionName}
											onChange={event => this.setState({ PromotionName: event.target.value })}
											inputProps={{
												ref: el => this.promoName = el
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Expiration Date</label>
										<DatePicker
											onChangeRaw={(e) => e.preventDefault()}
											customInput={<OutlinedInput
												variant="outlined"
												className="full-width"
												onKeyDown={(e) => e.preventDefault()}
												endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
											/>}
											className="datePicdesign dateField"
											selected={ExpirationDate}
											onChange={ExpirationDate => this.setState({ ExpirationDate })}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Fulfilment Date</label>
										<DatePicker
											onChangeRaw={(e) => e.preventDefault()}
											customInput={<OutlinedInput
												variant="outlined"
												onKeyDown={(e) => e.preventDefault()}
												className="full-width"
												endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
											/>}
											className="datePicdesign dateField"
											selected={FulfillmentDate}
											onChange={FulfillmentDate => this.setState({ FulfillmentDate })}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Funds</label>
										<CurrencyInput placeholder="" type="text"
											value={Funds || ''}
											currencySymbol="$"
											onChange={event => this.setState({ Funds: event.target.value })}
										/>
									</Box>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Box mx={1}>
										<label className="inputLabel">Status</label>
										<TextField
											fullWidth={true}
											select
											value={Status}
											autoComplete="off"
											variant="outlined"
											onChange={event => this.setState({ Status: event.target.value })}
											inputProps={{
												ref: el => this.status = el
											}}
										>
											{statusList ? statusList.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											)) :
												(
													<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
												)
											}
										</TextField>
									</Box>
								</Grid>
								<Grid item xs={6} sm={6}>
									<Box mx={1} display="flex" justifyContent="flex-end" alignItems="center">
										<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => this.clearFilter()} variant="outlined" color="primary" className="clearBtn">Clear</Button> </Link>
										{fetchingList ? (
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										) : (
											<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => {
												this.setState({ page: 0 }, () => {
													this.updatePromotionList()
												})
											}} variant="contained" color="primary">Search</Button> </Link>
										)}
									</Box>
								</Grid>
							</Grid>
						</Collapse>
					</Grid>
					<Grid item xs={11} md={11} lg={11} style={{ overflowX: 'auto' }}>
						<Table>
							<StyledTableHead>
								<TableRow>
									<StyledTableCell sortDirection={SortColumn === "PromotionId" ? SortOrder : false}>
										<TableSortLabel
											active={SortColumn === "PromotionId"}
											direction={SortColumn === "PromotionId" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("PromotionId")}
										>
											Promo ID
											{SortColumn === "PromotionId" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "PromotionName"}
											direction={SortColumn === "PromotionName" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("PromotionName")}
										>
											Promotion Name
											{SortColumn === "PromotionName" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "FulfillmentDate"}
											direction={SortColumn === "FulfillmentDate" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("FulfillmentDate")}
										>
											Fulfillment Date
											{SortColumn === "FulfillmentDate" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "ExpirationDate"}
											direction={SortColumn === "ExpirationDate" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("ExpirationDate")}
										>
											Expiration Date
											{SortColumn === "ExpirationDate" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "Funds"}
											direction={SortColumn === "Funds" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("Funds")}
										>
											Funds
											{SortColumn === "Funds" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "CreatedDate"}
											direction={SortColumn === "CreatedDate" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("CreatedDate")}
										>
											Created Date
											{SortColumn === "CreatedDate" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "UpdatedDate"}
											direction={SortColumn === "UpdatedDate" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("UpdatedDate")}
										>
											Modified Date
											{SortColumn === "UpdatedDate" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "Status"}
											direction={SortColumn === "Status" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("Status")}
										>
											Status
											{SortColumn === "Status" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
								</TableRow>
							</StyledTableHead>
							<TableBody>
								{fetchingList ? (
									<TableRow>
										<TableCell colSpan={8}>
											<Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										</TableCell>
									</TableRow>
								) : (
									promotion.list && promotion.list.map((item, index) => (
										<Fragment key={item.PromotionID}>
											<StyledTableRow onClick={e => this.toggleListItem(item, index)}>
												<StyledTableCell>{item.PromotionID}</StyledTableCell>
												<StyledTableCell>{item.PromotionName}</StyledTableCell>
												<StyledTableCell>{item.FulfillmentDate ? moment(item.FulfillmentDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
												<StyledTableCell>{item.ExpirationDate ? moment(item.ExpirationDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
												<StyledTableCell>{currency(item.Funds, { formatWithSymbol: true }).format()}</StyledTableCell>
												<StyledTableCell>{item.CreatedDate ? moment(item.CreatedDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
												<StyledTableCell>{item.UpdatedDate ? moment(item.UpdatedDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
												<StyledTableCell>{this.statusText(item.Status)}</StyledTableCell>
											</StyledTableRow>
											<TableRow>
												<TableCell colSpan={8} className="no-padding">
													<Collapse hidden={!(expandedItem === index)} in={expandedItem === index}>
														<div className="expanded-view">
															{fetchingDetail ? (
																<Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
															) : (
																<Fragment>
																	<FundOverview
																		params={{
																			showTitle: false
																		}}
																		assignedFunds={{
																			fundTitle: "Funds In Progress",
																			total: (PromotionRewards.length) ? PromotionRewards[0].FundInProgress : 0,
																			data: PromotionRewards
																		}}
																		expiredFunds={{
																			fundTitle: "Allocated Funds",
																			total: (PromotionRewards.length) ? PromotionRewards[0].AllocatedFund : 0,
																		}}
																	/>
																	<div className="promotion-expanded-bottom">
																		<div className="promotion-expanded-button-container">
																			{claims && claims.includes("promotion_create") &&
																				<Button disabled={item.Status == 6} onClick={() => this.handlePromotionEdit(item.PromotionID)} variant="contained" color="primary" style={{ textTransform: 'capitalize' }}>
																					{/* <Link to="#" className={`${item.Status == 6 ? 'black' : 'white'}`}>
																						Edit
																					</Link> */}
																					Edit
																				</Button>
																			}
																		</div>
																		{parseInt(item.Status) > 3 && (
																			<Fragment>
																				<div className="promotion-expanded-item">
																					<span>Officers Associated</span>
																					<span>{PromotionDetailOverview ? PromotionDetailOverview.OfficerAssocated : 0}</span>
																				</div>
																				<div className="promotion-expanded-item">
																					<span>Expired Rewards</span>
																					<span>{PromotionDetailOverview ? PromotionDetailOverview.ExpiredRewards : 0}</span>
																				</div>
																			</Fragment>
																		)}
																	</div>
																</Fragment>
															)}
														</div>
													</Collapse>
												</TableCell>
											</TableRow>
										</Fragment>
									))
								)}
							</TableBody>
							<StyledTableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[10, 25, 50, 100]}
										colSpan={8}
										count={promotion.totalCount || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: { 'aria-label': 'rows per page' },
											native: true,
										}}
										onChangePage={this.handlePageChange}
										onChangeRowsPerPage={this.handleRowsPerPageChange}
									/>
								</TableRow>
							</StyledTableFooter>
						</Table>
					</Grid>
				</Grid>
				{promotion.createError && this.renderSnackbar(promotion.createError)}
			</Fragment>
		)
	}

	renderSnackbar = message => {
		return <Notification variant="error" message={message} />
	}

	statusText = statusId => {
		switch (parseInt(statusId)) {
			case 1:
				return 'Draft'
			case 2:
				return 'Draft'
			case 3:
				return 'Open'
			case 4:
				return 'In Progress'
			case 5:
				return 'Expired'
			case 6:
				return 'Closed'
		}
	}
}

export default connect(state => ({ ...state.user, ...state.promotion, ...state.permissions }))(PromotionListView)