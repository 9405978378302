import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import App from '~/App'
import { Provider } from 'react-redux'
import store from '~/redux'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#0558FF'
		}
	},
	typography: {
		fontSize: 14,
	},
	overrides: {
		MuiInputBase: {
			root: {
				fontSize: '14px',
				fontFamily: 'USBankCircular',
			}
		},
		MuiTextField: {
			root: {
				'&:focus': {
					outline: 'none'
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderWidth: 1,
				},
			}
		},
		MuiButtonBase: {
			root: {
				fontFamily: 'USBankCircular',
			}
		},
		MuiButton: {
			root: {
				fontFamily: 'USBankCircular',
				borderRadius: 0,
				padding: '.5rem 2rem',
				textTransform: 'capitalize',
				padding: '0.25rem 1.5rem',
				fontWeight: 'normal',
				fontSize: '14px',
				'&$outlinedPrimary': {
					border: '2px solid #e6e6e6',
					backgroundColor: '#e6e6e6',
					color: '#000',
				},
				'&$outlinedPrimary:hover': {
					border: '2px solid #000',
					color: '#000',
					backgroundColor: '#fff',
				},
				'&$containedPrimary': {
					border: '2px solid #0558FF',
					backgroundColor: '#0558FF',
					color: '#fff',
				},
				'&$containedPrimary:hover': {
					border: '2px solid #0558FF',
					color: '#0558FF',
					backgroundColor: '#fff',
				},
				'&$contained': {
					boxShadow: 'none',
					'&$Mui': {
						'&$disabled': {
							border: '2px solid red',
						}
					}
				},
				'&$contained:hover': {
					boxShadow: 'none',
				},
				'&$containedSecondary': {
					border: '2px solid #e6e6e6',
					backgroundColor: '#e6e6e6',
					color: '#000',
				},
				'&$containedSecondary:hover': {
					border: '2px solid #000',
					color: '#000',
					backgroundColor: '#fff',
				},
				'&$containedSizeSmall': {
					fontSize:'14px',
				}
			},
		},
		MuiDialog: {
			paperScrollPaper: {
				borderRadius: 0,
				// padding: '3.125rem 2.75rem'
			}
		},
		MuiDialogTitle: {
			root: {
				padding: 0
			}
		},
		MuiSvgIcon: {
			root: {
				cursor: 'pointer'
			}
		},
		PrivateNotchedOutline: {
			root: {
				borderRadius: 0,
			}
		},
		MuiTab: {
			root: {
				fontFamily: 'USBankCircular',
				textTransform: 'capitalize',
			},
			textColorInherit: {
				opacity: 'inherit',
			}
		},
		MuiTableHead: {
			head: {
				fontFamily: 'USBankCircular',
				color: '#000',
				fontWeight: 'bold',
				opacity: 'inherit',
			},
			root: {
				fontFamily: 'USBankCircular',
				color: '#000 !important',
				fontWeight: 'bold',
				opacity: 'inherit !important',
			}
		},
		MuiTableCell: {
			root: {
				fontFamily: 'USBankCircular',
				color: '#000',
			},
			head: {
				fontFamily: 'USBankCircular',
				color: '#000',
				fontWeight: 'bold',
			},
			body: {
				fontFamily: 'USBankCircular',
				color: '#000',
			}
		},
		MuiTablePagination: {
			root: {
				fontFamily: 'USBankCircular',
				color: '#000',
			}
		},
		MuiTableFooter: {
			root: {
				fontFamily: 'USBankCircular',
				color: '#000 !important',
				opacity: 'inherit !important',
			}
		},
		MuiTableSortLabel: {
			root: {
				color: '#000 !important',
			},
			icon: {
				color: '#000 !important',
			},
			active: {
				color: '#000 !important',
			}
		},
		MuiMenuItem: {
			root: {
				fontFamily: 'USBankCircular',
			}
		},
		MuiTypography: {
			body: {
				fontFamily: 'USBankCircular',
			},
			body2: {
				fontFamily: 'USBankCircular',
			},
			h1: {
				fontFamily: 'USBankCircular',
			},
			h2: {
				fontFamily: 'USBankCircular',
			},
			h3: {
				fontFamily: 'USBankCircular',
			},
			h4: {
				fontFamily: 'USBankCircular',
			},
			h5: {
				fontFamily: 'USBankCircular',
			},
			h6: {
				fontFamily: 'USBankCircular',
			}
		},
		MuiNativeSelect: {
			select: {
				fontFamily: 'USBankCircular',
			}
		},
		MuiAvatar: {
			root: {
				fontFamily: 'USBankCircular',
			}
		},
		MuiPaper: {
			root: {
				color: '#000',
			}
		},
	}
})

ReactDOM.render((
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
			<App />
		</Provider>
	</MuiThemeProvider>
), document.getElementById('root'))
