import Cookies from "universal-cookie";
import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers/user";
import moment from 'moment'
export const fetchUsersList = ({ clientId, pageSize, pageNo, SortColumn, SortOrder, IsDownload }) => async dispatch => {
  try {
    let data = {};
    if (IsDownload) {
      data = {
        clientId: clientId,
        isDownload: IsDownload || 0
      }
    }
    else {
      data = {
        clientId: clientId,
        pageSize: pageSize,
        pageNo: pageNo,
        SortColumn: SortColumn,
        SortOrder: SortOrder,
        isDownload: IsDownload || 0
      }
    }
    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Settings/GetAllUsers`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/GetAllUsers`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(data)
    });

    const responseBody = await response.data;

    if(IsDownload){
      return responseBody;
    }

    if (!responseBody.error) {
      dispatch({
        type: "USERS_LIST_FETCH_SUCCESS",
        payload: responseBody.userDto,
        totalCount: responseBody.TotalCount,
        pageSize: responseBody.pageSize,
        currentPage: responseBody.CurrentPage
      });
      return true;
    }

    dispatch({
      type: "USERS_LIST_FETCH_FAILED",
      payload: responseBody.error || "Oops! Something went wrong."
    });
    return false;

  } catch (error) {
    dispatch({
      type: "USERS_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured."
    });
    return false;
  }
};

export const createUser = ({ FirstName, LastName, UserName, PasswordHash, EmailAddress, PhoneNumber, UserTypeId, CreatedBy }) => async dispatch => {
  try {
    const accessToken = await getAccessToken();
    const newUser = { FirstName: FirstName, LastName: LastName, UserName: UserName, PasswordHash: PasswordHash, EmailAddress: EmailAddress, PhoneNumber: PhoneNumber, UserTypeId: UserTypeId, CreatedBy: CreatedBy }
    const response = await axios({
      url: `${config.base_url}/api/Settings/CreateAdminUser`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/CreateAdminUser`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(newUser)
    });

    const responseBody = await response.data;
    if (!responseBody.ErrorMessage) {
      newUser["id"] = responseBody.result;
      dispatch({
        type: "CREATE_ADMIN_USER_SUCCESS",
        payload: newUser
      });
      return {
        result: true,
        ErrorMessage: null
      }
    } else {
      dispatch({
        type: "CREATE_ADMIN_USER_FAILED",
        payload: responseBody.ErrorMessage || "Oops! Something went wrong."
      });
      return {
        result: false,
        ErrorMessage: responseBody.ErrorMessage
      }
    }
  } catch (error) {
    dispatch({
      type: "CREATE_ADMIN_USER_FAILED",
      payload: (error.response && error.response.data.error) || "An error has occured."
    });
    return false;
  }
};

export const updateUserDetails = ({ id, FirstName, LastName, UserName, PasswordHash, EmailAddress, PhoneNumber, UserTypeId, LastUpdatedBy }) => async dispatch => {
  try {
    const accessToken = await getAccessToken();
    const user = { 
      id: id || "", 
      FirstName: FirstName || "", 
      LastName: LastName || "", 
      UserName: UserName || "", 
      PasswordHash: PasswordHash || null, 
      EmailAddress: EmailAddress || "",
      PhoneNumber: PhoneNumber || "", 
      UserTypeId: UserTypeId || "", 
      LastUpdatedBy: 1
    }
    const response = await axios({
      url: `${config.base_url}/api/Settings/UpdateUserDetails`,
      //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/UpdateUserDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `${accessToken}`,
      },
      data: JSON.stringify(user)
    });

    const responseBody = await response.data;
    if (!responseBody.ErrorMessage) {
      dispatch({
        type: "UPDATE_USER_DETAILS_SUCCESS",
        payload: responseBody.result
      });
      return {
        result: true,
        ErrorMessage: null
      }
    } else {
      dispatch({
        type: "UPDATE_USER_DETAILS_FAILED",
        payload: responseBody.ErrorMessage.Message || "Oops! Something went wrong."
      });
      return {
        result: false,
        ErrorMessage: responseBody.ErrorMessage
      }
    }
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_DETAILS_FAILED",
      payload: error.ErrorMessage || "An error has occured."
    });
    return false;
  }
};

export const fetchRenewalDate = ({userId}) => async dispatch => {
    try {
        const accessToken = await getAccessToken();
        const response = await axios({
          url: `${config.base_url}/api/Settings/GetW9ConfigurationDate`,
          //url: `https://glq8b8cm10.execute-api.us-east-2.amazonaws.com/dev/GetW9ConfigurationDate`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `${accessToken}`,
          },
          data: JSON.stringify({
              lUserID: userId|| 0
          })
        });

        const responseBody = await response.data;
        if (!responseBody.error) {
          dispatch({
            type: "RENEWWALDATE_FETCH_SUCCESS",
            payload: responseBody.Data?responseBody.Data[0].Date:'',
          });
          return true;
        }

        dispatch({
          type: "RENEWWALDATE_FETCH_FAILED",
          payload: responseBody.error || "Oops! Something went wrong."
        });
        return false;

    } catch (error) {
        dispatch({
          type: "RENEWWALDATE_FETCH_FAILED",
          payload: error.message || "An error has occured."
        });
        return false;
    }
};

export const saveRenewalDate = ({ renewalDate, userId}) => async dispatch => {
    try {
        const accessToken = await getAccessToken();
        const response = await axios({
            url: `${config.base_url}/api/Settings/UpdateW9ConfigurationDate`,
            //url: `https://glq8b8cm10.execute-api.us-east-2.amazonaws.com/dev/UpdateW9ConfigurationDate`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${accessToken}`,
           },
           data: JSON.stringify({
               IConfigurationDate: renewalDate ? moment(renewalDate).format("YYYY-MM-DD") : "",
               lUserID: userId|| 0
           })
        });

        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: "SAVE_RENEWWALDATE_SUCCESS",
                payload: renewalDate ? moment(renewalDate).format("MM/DD/YYYY") : "",
            });
            return true;
        }
        dispatch({
          type: "SAVE_RENEWWALDATE_FAILED",
          payload: responseBody.error || "Oops! Something went wrong."
        });
        return false;

    } catch (error) {
        dispatch({
            type: 'SAVE_RENEWWALDATE_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
};
export const fetchTaxGroupDate = ({userId}) => async dispatch => {
    try {
        const accessToken = await getAccessToken();
        const response = await axios({
          url: `${config.base_url}/api/Settings/GetW9SubmissionDate`,
          //url: `https://glq8b8cm10.execute-api.us-east-2.amazonaws.com/dev/GetW9SubmissionDate`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `${accessToken}`,
          },
          data: JSON.stringify({
              lUserID: userId|| 0
          })
        });

        const responseBody = await response.data;
        if (!responseBody.error) {
          dispatch({
            type: "SUBMISSIONDATE_FETCH_SUCCESS",
            payload: responseBody.Data?responseBody.Data[0].Date:'',
          });
          return true;
        }

        dispatch({
          type: "SUBMISSIONDATE_FETCH_FAILED",
          payload: responseBody.error || "Oops! Something went wrong."
        });
        return false;

    } catch (error) {
        dispatch({
          type: "SUBMISSIONDATE_FETCH_FAILED",
          payload: error.message || "An error has occured."
        });
        return false;
    }
};

export const saveTaxGroupDate = ({ taxGroupDate, userId}) => async dispatch => {
    try {
        const accessToken = await getAccessToken();
        const response = await axios({
            url: `${config.base_url}/api/Settings/UpdateW9SubmissionDate`,
            //url: `https://glq8b8cm10.execute-api.us-east-2.amazonaws.com/dev/UpdateW9SubmissionDate`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${accessToken}`,
           },
           data: JSON.stringify({
               ISubmissionDate: taxGroupDate ? moment(taxGroupDate).format("YYYY-MM-DD") : "",
               lUserID: userId|| 0
           })
        });

        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: "SAVE_SUBMISSIONDATE_SUCCESS",
                payload: taxGroupDate ? moment(taxGroupDate).format("MM/DD/YYYY") : "",
            });
            return true;
        }
        dispatch({
          type: "SAVE_SUBMISSIONDATE_FAILED",
          payload: responseBody.error || "Oops! Something went wrong."
        });
        return false;

    } catch (error) {
        dispatch({
            type: 'SAVE_SUBMISSIONDATE_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
};