import React, { Component, Fragment }  from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Grid, Box, Link } from '@material-ui/core';
import { userInfo, logout } from '~/redux/actions/user';
import { keepSessionLive, checkSessionTimout } from '~/redux/helpers/user';
import Header from '~/components/Header'
import Login from '~/views/Login'
import Promotion from '~/views/Promotion'
import Rewards from '~/views/Rewards';
import Support from '~/views/Support';
import Settings from '~/views/Settings';
import Reports from '~/views/Reports';
import Taxation from '~/views/Taxation';
import { fetchUserPermissionsMinified } from '~/redux/actions/permissions';
import { ErrorPage } from "~/views/misc/error.js";

import IdleTimer from 'react-idle-timer';

import '~/App.scss'
import 'typeface-roboto'

class AuthRoute extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timeout:1000 * 5 * 12 * 10,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    componentWillUnmount() {
        clearInterval(this.pingSession);
        clearInterval(this.pingKeepSessionLive);
    }

    componentDidMount() {
        const { info } = this.props.user;
        if (info) {
            this.props.dispatch(fetchUserPermissionsMinified({ UserId: info && info.Id, ClientId: 1 }));
        }

        //This will run in every 5 minutues to check token is valid or not
        this.pingSession = setInterval(() => {
            console.log("ping ");
            try {
                const checkSession = checkSessionTimout().then((response) =>{
                    console.log("check ping response", response);
                    if(!response) {
                        console.log("logout ping");
                        this.props.dispatch(logout());
                    }
                });
            } catch(ex) {
                console.log("Exception ping");
                this.props.dispatch(logout());
            }
        }, 270000);

        //This will update token in every 10 minutues case user is not idle, If user id idle then logout 
        this.pingKeepSessionLive = setInterval(() => {
            console.log("keepSessionLive ");
            //If user idle for 10 minutues logout
            if(this.idleTimer && this.idleTimer.isIdle()){
                console.log("keepSessionLive logout");
                this.props.dispatch(logout());
            } else {
                try {
                    const updatedSession = keepSessionLive().then((response) => {
                        console.log("keepSessionLive response", response);
                        if(!response) {
                            console.log("logout keepSessionLive");
                            this.props.dispatch(logout());
                        }
                    })
                } catch(ex) {
                    console.log("Exception keepSessionLive");
                    this.props.dispatch(logout());
                }
            }

        }, 600000);

    }

    _onAction(e) {
        console.log('On action');
        //console.log('last active time', Date(this.idleTimer.getLastActiveTime()));
        //console.log('time remaining', this.idleTimer.getRemainingTime()/60000);
        //console.log('time elapsed', this.idleTimer.getElapsedTime()/60000);
       // console.log('id idle', this.idleTimer.isIdle());
    }

    _onActive(e) {
        console.log('On active');
        
    }

    _onIdle(e) {
        console.log('On idle');
        this.props.dispatch(logout());
    }

    render() {
        const { component: Component, isLoggedIn, passwordChanged, ...rest } = this.props;
        const { info } = this.props.user;
        const { minified } = this.props.permissions;
        
        return (
            <Route {...rest} render={(props) => (
                (isLoggedIn && passwordChanged) ?
                    <Fragment>
                        <Header {...props} claims={minified} info={info} />
                        <Box pt={5}>
                            <IdleTimer
                              ref={ref => { this.idleTimer = ref }}
                              element={document}
                              onActive={this.onActive}
                              onIdle={this.onIdle}
                              onAction={this.onAction}
                              debounce ={250}
                              timeout={this.state.timeout} />

                            <Component {...props} claims={minified} info={info} />
                        </Box>
                    </Fragment>
                    : <Redirect to='/' />
            )} />
        )
    }
}
let ProtectedRoutes = connect(state => (
    { ...state.user, ...state.permissions }
))(AuthRoute);

class App extends Component {
    state = {
        isLoading: true,
        metadata: {title:"Elan Financial Services | Admin Portal"}
    }
    componentDidMount() {
        this.props.dispatch(userInfo()).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }
    render() {
        const { isLoggedIn, info: { PasswordChangedDate } } = this.props.user;
        const { isLoading, metadata } = this.state;
        const { info } = this.props.user;
 
        if (isLoading) {
            return null
        }
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Helmet>
                        <title>{metadata.title}</title>
                        <meta name="title" content={metadata.title} />
                        <meta name="description" content={metadata.description} />
                        <meta name="keywords" content={metadata.keywords} />
                    </Helmet>

                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/error' component={ErrorPage} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/promotion' component={Promotion} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/rewards' component={Rewards} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/settings' component={Settings} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/support' component={Support} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/reports' component={Reports} />
                        <ProtectedRoutes isLoggedIn={isLoggedIn} passwordChanged={PasswordChangedDate} path='/taxation' component={Taxation} />
                    </Switch>
                </React.Fragment>
            </BrowserRouter>
        )
    }
}

export default connect(state => (
    { ...state.user, ...state.permissions }
))(App); 