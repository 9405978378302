import Cookies from "universal-cookie";
import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers/user";
import moment from "moment";

export const fetchSummaryReport = ({ fromDate, toDate, IsDownload }) => async (
  dispatch
) => {
  try {
    const queryFromDate = moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss");
    const queryToDate = moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss");

    dispatch({
      type: "REPORT_LIST_FETCH_START",
    });

    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Reports/getpromotionreport`,
      //url: `https://ni7rl46c1h.execute-api.us-east-2.amazonaws.com/dev/getpromotionreport`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        ToDate: queryToDate || "",
        FromDate: queryFromDate || "",
        IsDownload: IsDownload,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_LIST_FETCH_SUCCESS",
        payload: JSON.parse(responseBody).Data,
      });
      return true;
    }
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: JSON.parse(responseBody).error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};

export const fetchFundSummaryReport = ({fromDate, toDate, IsDownload }) => async (dispatch) => {
  try {
    dispatch({
      type: "REPORT_LIST_FETCH_START",
    });

    const queryFromDate = moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss");
    const queryToDate = moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss");

    const accessToken = await getAccessToken();
    const response = await axios({
    url: `${config.base_url}/api/Reports/GetPromotionReportFundDetails`,
      //url: `https://0470qhfz21.execute-api.us-east-2.amazonaws.com/uat/GetPromotionReportFundDetails`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        ToDate: queryToDate || "",
        FromDate: queryFromDate || "",
        IsDownload: IsDownload,
       //TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_LIST_FETCH_SUCCESS",
       // payload: JSON.parse(responseBody).Data,
       payload: JSON.parse(JSON.stringify(responseBody)).Data,
      });
      return true;
    }
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: JSON.parse(JSON.stringify(responseBody)).error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};

export const fetchReconciliationReport = ({
  fromDate,
  toDate,
  IsDownload,
}) => async (dispatch) => {
  try {
    const queryFromDate = moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss");
    const queryToDate = moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss");

    dispatch({
      type: "REPORT_LIST_FETCH_START",
    });
    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Reports/getreconciliationreport`,
      //url: `https://ni7rl46c1h.execute-api.us-east-2.amazonaws.com/dev/getreconciliationreport`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        ToDate: queryToDate || "",
        FromDate: queryFromDate || "",
        IsDownload: IsDownload,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_LIST_FETCH_SUCCESS",
        payload: responseBody.Data,
      });
      return true;
    }
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: responseBody.error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};

export const fetchOutstandingReport = ({
  fromDate,
  toDate,
  filters,
  IsDownload,
}) => async (dispatch) => {
  try {
    const queryFromDate = moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss");
    const queryToDate = moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss");

    dispatch({
      type: "REPORT_LIST_FETCH_START",
    });
    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Reports/getoutstandingreport`,
      //url: `https://ni7rl46c1h.execute-api.us-east-2.amazonaws.com/dev/getoutstandingreport`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        ToDate: queryToDate || "",
        FromDate: queryFromDate || "",
        IsDownload: IsDownload,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        PortBu: filters["PortBu"] || "",
        LocationCode: filters["LocationCode"] || "",
        OfficeId: filters["OfficeId"] || "",
        OfficeName: filters["OfficeName"] || "",
        RewardStatus: filters["RewardStatus"] || "",
        PromotionID:filters["PromotionID"] || "",
        SortColumn: "",
        SortOrder: ""
      }),
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_LIST_FETCH_SUCCESS",
        payload: responseBody.Data,
      });
      return true;
    }
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: responseBody.error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};

export const fetchYearList = () => async (dispatch) => {
  try {
    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Taxation/W9GetYear`,
      //url: `https://7j6vnigzd7.execute-api.us-east-2.amazonaws.com/dev/W9GetYear`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_YEAR_LIST_FETCH_SUCCESS",
        payload: responseBody.Data,
        currentYear: responseBody.Data ? responseBody.Data[0].value : "",
      });
      return responseBody;
    }
    dispatch({
      type: "REPORT_YEAR_LIST_FETCH_FAILED",
      payload: responseBody.error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_YEAR_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};

export const fetchMergeReport = ({
  fromDate,
  toDate,
  selectedYear,
  IsDownload,
}) => async (dispatch) => {
  try {
    const queryFromDate = moment(fromDate).utc().format("YYYY-MM-DD HH:mm:ss");
    const queryToDate = moment(toDate).utc().format("YYYY-MM-DD HH:mm:ss");

    dispatch({
      type: "REPORT_LIST_FETCH_START",
    });
    const accessToken = await getAccessToken();
    const response = await axios({
      url: `${config.base_url}/api/Reports/getmergedreport`,
      //url: `https://ni7rl46c1h.execute-api.us-east-2.amazonaws.com/dev/getmergedreport`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`,
      },
      data: JSON.stringify({
        ToDate: queryToDate || "",
        FromDate: queryFromDate || "",
        IsDownload: IsDownload,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        //FinancialYear: selectedYear
      }),
    });
    const responseBody = await response.data;
    if (!responseBody.error) {
      dispatch({
        type: "REPORT_LIST_FETCH_SUCCESS",
        payload: responseBody.Data,
      });
      return true;
    }
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: JSON.parse(responseBody).error || "Oops! Something went wrong.",
    });
    return false;
  } catch (error) {
    dispatch({
      type: "REPORT_LIST_FETCH_FAILED",
      payload: error.message || "An error has occured.",
    });
    return false;
  }
};
